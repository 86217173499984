
import { Options, Vue } from 'vue-class-component';
import { apiReportGetListTotalNum,
         apiReportGetList } from '../services/tpsApi/tpsApiReport';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '../services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import Alert from '@/components/Alert.vue';
import { exportFile } from '../services/exportFile';
import { 
    IReportGetListTotalNumRequestParams,
    IReportGetListRequestParams
 } from '../services/tpsApi/models/IReport';
import ReportVersionMenu from '@/components/ReportVersionMenu.vue';
import Table from '@/components/Table.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    ReportVersionMenu,
    Table,
    Pagination,
    Alert
  },
})
export default class Report extends Vue {
    TABLE_HEAD: {} = {
        brand_id: "品牌名稱",
        reader_number: "卡機號碼",
        version_date: "生效時間",
        program_version: "卡機軟體版本",
        firmware_version: "卡機韌體版本",
        operation_rule_version: "營運參數版本",
        payment_version: "付款參數版本",
        create_user: "建立帳戶",
        create_date: "建立時間"

    };
    $refs!: {
        alert: Alert
    }

    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    reportList: [] | Blob = [];
    REPORT = "7"; //report=7，表示產生第七種業者報表，卡機版本紀錄表
    pageNo: {} = PAGENO;
    searchQuery: {} = {};

    mounted() {
        this.refreshTabel({report: this.REPORT, limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination() {
        let query = Object.assign({report: this.REPORT}, this.searchQuery);
        this.pageTotalNum = await apiReportGetListTotalNum(query);       
        console.log(this.pageTotalNum);
    }

    async refreshTabel(pData: IReportGetListRequestParams) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        if(pData && !pData.report) pData.report = this.REPORT;
        pData = Object.assign(pData, this.searchQuery);
        this.reportList = await apiReportGetList(pData);
    }

    async download(pData: { output: string}) {
        const res = await apiReportGetList(Object.assign({
                                            report: this.REPORT, 
                                            output: pData.output},
                                            this.searchQuery));
        exportFile({ fileName: "卡機版本總表", data: res });
    }

    doSearch(pData) {
        this.searchQuery = Object.assign({}, pData);
        this.initPagination();
        this.refreshTabel({report: this.REPORT, limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
    }
    doAlert(pData: {alertType: string, msg: string}) {
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
    } 
}
