
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { apiListMenuGetItem } from '../services/tpsApi/tpsApiListMenuItem';
import { apiBrandGetListName } from '../services/tpsApi/tpsApiBrand';
import DatepickerLite from "vue3-datepicker-lite";
import { apiUploadFile } from '../services/tpsApi/tpsApiImportByFile';
@Options({
  components: {
    DatepickerLite
  },
  emits: [
      "form",
      "alert",
      "export",
      "search"
  ]
})


export default class ReportVersionMenu extends Vue {
    $refs!: {
        file
    }
    formData: {} = {};
    brandSelectOptions: {} = {};
    

    @Prop({ required: false }) create: boolean = true;
    @Prop({ required: false }) table: string = "";

    file: string | Blob = "";
    REPORT = "7"; //report=1，表示產生第一種業者報表，交易明細表
    
    WRITE: number | undefined = undefined;
    permission = undefined;

    async beforeMount() {
        this.WRITE = this.$store.getters.write;
  }


    async mounted() {
        this.permission = this.$store.getters.permission;
        console.log("permission:",this.permission);
        //Select options setting
        this.brandSelectOptions = await apiBrandGetListName();
    }


    @Emit("alert")
    doAlert(pAlertType, pMsg): {alertType: string, msg: string} {
        return {alertType: pAlertType, msg: pMsg};
    }
    search() {  

            let data = {
                brand_id: this.formData["brand_id"],
                rsn: this.formData["rsn"]
            };

            this.doSearch(data);
        
    }

    @Emit("search")
    doSearch(pData) {
        return pData;
    }
    importFile() {
        this.$refs.file.value = null;
        this.$refs.file.click();
    }
  
    async fileUpload(event) {
        this.file = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append('file', this.file);
        console.log("file:",this.file);
        console.log("table:",this.table);
        const res = await apiUploadFile({table:this.table, data:formData});
        if(res.msg === ("ok")) {this.doAlert("success", "上傳成功");}
        else{ this.doAlert("fail", res.msg); }
    }
}
